.Divider-Line {
    border-bottom: 2px solid var(--text-color);
}

.Site-Title {
    padding-left: 10px;
    font-weight: bold;
    /* font-style: italic; */
    display: inline
}

.Nav-Bar, .Nav-Bar-Mobile{
    display: flex;
    justify-content: flex-end;
    padding-right: var(--minor-padding);
}

.Nav-Bar .About-Button, .Nav-Bar-Mobile .About-Button {
    border-right: 2px solid var(--text-color);
    border-left: 2px solid var(--text-color);
    padding-left: var(--minor-padding);
    padding-right: var(--minor-padding);
}

.Nav-Bar .Projects-Button, .Nav-Bar-Mobile .Projects-Button {
    border-right: 2px solid var(--text-color);
    border-left: 2px solid var(--text-color);
    margin-left: -2px;

    padding-left: var(--minor-padding);
    padding-right: var(--minor-padding);
}


.Nav-Bar .About-Button:hover {
    cursor: pointer;
    border-top: 2px solid var(--text-color);
    border-bottom: 2px solid var(--text-color);
    margin-top: -2px;
    margin-bottom: -2px;
}

.Nav-Bar .Projects-Button:hover {
    cursor: pointer;
    border-top: 2px solid var(--text-color);
    border-bottom: 2px solid var(--text-color);
    margin-top: -2px;
    margin-bottom: -2px;    
}