.main{
    display: flex;
    flex-direction: column;
    /* align-items: flex-start;     */
    border-top-width: 0;
    border-bottom-width: 2px;
    border-left-width: 0;
    border-right-width: 0;
    border-color: var(--text-color);
    border-style: solid;
    padding-left: var(--major-padding);    
    padding-right: var(--major-padding);
    word-wrap: break-word;
}

.image-main{
    display: flex;          

    border-top-width: 0;
    border-bottom-width: 2px;
    border-left-width: 0;
    border-right-width: 0;
    border-color: var(--text-color);
    border-style: solid;
    padding-left: var(--major-padding);    
}

.image-main-mobile{
    display: flex;      
    flex-direction: column;  
    border-top-width: 0;
    border-bottom-width: 2px;
    border-left-width: 0;
    border-right-width: 0;
    border-color: var(--text-color);
    border-style: solid;
    padding-left: var(--major-padding);    
    word-wrap: break-word;
}

#preview-image{
    object-fit: cover;
    max-width: 300px;
    max-height: 90%;
    max-height: 300px;
    
    
    padding-right: var(--major-padding);
    padding-top: 3.5%;
    /* padding-bottom: 5%; */
    /* padding-top: var(--minor-padding); */
    /* padding-bottom: var(--minor-padding); */
        
    cursor:pointer;
    /* border: 1px solid red; */
}

.image-main img:hover{
    opacity: .7;
}


.Heading{
    cursor: pointer;
    margin-bottom: var(--minor-padding)*-1;    
}

.Heading:hover{
    text-decoration: underline;
}

.More-Info{
    font-weight: bold;
    cursor: pointer;
}

.More-Info:hover{
    text-decoration: underline;
}