.project-back{
    cursor: pointer;
    padding-left: var(--minor-padding);
}

.project-back:hover{
    text-decoration: underline;
}

.post-container{
    padding-left: var(--major-padding);
    padding-right: var(--major-padding);    
}

.post-container h1{
    
    text-align: center;
    font-size: 200%;

}

.post-container h3{
    text-decoration: underline;
    font-size: 150%;
}

.post-container p{    
    font-size: 120%;
}

.speakup-video-container{
    /* border: 2px solid red; */
    display: flex;
}

.speakup-video{
    max-width: 300px;
    /* border: 2px solid red; */
    margin-left: auto;
    margin-right: auto;
}

.post-container img{
    max-width: 100%;
    /* max-width: 375px; */
}