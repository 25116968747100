.About-Container{
    /* display:flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    padding-left: var(--major-padding);
    padding-right: var(--major-padding);
    font-size: 150%;
}

.about-image-holder{
    /* border: 1px solid blue;     */
    text-align: center;
}

.About-Container img{
    object-fit: cover;
    max-width: 900px;
    width: 100%;
    height: auto;    
    margin-bottom: -20px;
    /* margin-left: auto; */
    /* margin-right: auto; */
    /* border: 1px solid red; */
    /* margin-top: -200px; */
}

.Text-Container{    
    max-width: 900px;
}

.about-down-arrow{
    text-align: center;
    font-size: 200%;
}