@import url('https://fonts.googleapis.com/css?family=Space+Mono:normal,bold,bolditalic');

body {
  margin: 0;
  font-family: 'Space Mono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
  /* font-size: 200%; */
}


:root{
  --background-color: #F6EB80;
  --text-color: #000AFF;  
  --minor-padding: 10px;
  --major-padding: 20px;
}